


import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { mapState } from 'vuex';
import SearchBar from '@/_components/search-bar/search-bar.vue';
import Tabs from '@/_ui/tabs/tabs.vue';
import chooseCompanyCategories from '@/views/components/popups/chooseCompanyCategories.vue';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TTab } from '@/_ui/tabs/types/tabs.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TPavilion } from '@/_types/promo-page/pavilion.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TEventTag } from '@/_types/event-tag.type';
import { TCountry } from '@/_types/country.type';
import SegmentControl from '@/_ui/segment-control/segment-control.vue';
import IconEwStar from '@/_modules/icons/components/icon-ew-star.vue';
import IconEwList from '@/_modules/icons/components/icon-ew-list.vue';
import IconEwMap from '@/_modules/icons/components/icon-ew-map.vue';
import PageTitle from '@/_components/page-title/page-title.vue';

type TCategoriesChooserMethods = {
  handleTagSelected: (tagData: TEventTag, index: number) => void;
}

@Component({
  components: {
    SearchBar,
    PageTitle,
    Tabs,
    chooseCompanyCategories,
    SegmentControl,
    IconEwStar,
    IconEwList,
    IconEwMap
  },
  computed: {
    ...mapState({
      pavilions: state => (state as TAppStoreState).promoStore.pavilions, // TODO move to newer syntax store, use vuex-class getters
      eventTags: state => (state as TAppStoreState).eventStore.eventTags, // TODO move to newer syntax store, use vuex-class getters
    }),
  },
})
export default class CompaniesViewer extends Vue {

  @Getter('promoPageStore/isMediaBlockOverlayVisible') isCompanyMediaBlockOverlayVisible: boolean;
  @Getter('promoPageStore/isViewTypeMap') isViewTypeMap: boolean;
  @Getter('promoPageStore/isViewTypeList') isViewTypeList: boolean;
  @Getter('seatPlanStore/isPlanJson') isPlanJson: boolean;
  @Getter('seatPlanStore/planJson') planJson: any;
  @Getter('seatPlanStore/isLoading') planIsLoading: boolean;
  @Getter('seatPlanStore/eventId') planEventId: number;
  @Getter('locationStore/countries') getCountryList: TCountry[];
  @Getter('promoPageStore/getListAll') getListAll: TPromoPage[];
  @Getter('promoPageStore/getListMy') getListMy: TPromoPage[];
  @Getter('promoPageStore/isListTypeAll') isListTypeAll: boolean;
  @Getter('promoPageStore/isListTypeMy') isListTypeMy: boolean;

  @Action('promoPageStore/setListAll') setListAll: () => Promise<TPromoPage[]>;
  @Action('promoPageStore/setListMy') setListMy: () => Promise<TPromoPage[]>;
  @Action('promoStore/promoPageListMy') promoPageListMy: (params: { event_id: string }) => Promise<void>;
  @Action('promoStore/promoPageListAll') promoPageListAll: (params: { event_id: string }) => Promise<void>;
  @Action('promoStore/setCompaniesListType') setCompaniesListType: (listTypeValue: string) => Promise<void>;
  @Action('seatPlanStore/getSeatPlanJson') getSeatPlanJson: (params: { eventId: number }) => Promise<void>;

  // vars from mapState
  public pavilions: TApiListResponse<TPavilion>;
  public eventTags: TEventTag[];

  // data
  public selectedTags: number[] | TEventTag[] = [];
  public showCompanyFilters: boolean = false;
  public selectedPavilionId: number = null;
  public isPromoPageNavigationInvisible: boolean = false;
  public companyListTypeTabs: TTab[] = [
    {
      title: this.$t('navigation.all'),
      isActive: true,
      index: 0,
    },
    {
      title: this.$t('navigation.my'),
      isActive: false,
      index: 1,
    },
  ];
  public companyViewTypeTabs: TTab[] = [
    {
      title: this.$t('navigation.list'),
      isActive: true,
      index: 0,
    },
    {
      title: this.$t('navigation.map'),
      isActive: false,
      index: 1,
    },
  ];

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isFilterSearchDisabled(): boolean {
    return false; // TODO: real logic
  }

  public get isCompanyCategoriesChooserVisible(): boolean {
    return !!(this.$route.meta && this.$route.meta.isCompanyCategoriesChooserVisible);
  }

  public get querySearch(): string {
    return ('' + (this.$route.query.search || '')).trim();
  }

  public get isAllCompaniesTabActive(): boolean {
    return !!(this.companyListTypeTabs.find(tab => tab.isActive && tab.index === 0));
  }

  public get isMyCompaniesTabActive(): boolean {
    return !!(this.companyListTypeTabs.find(tab => tab.isActive && tab.index === 1));
  }

  public get isListTabActive(): boolean {
    return !!(this.companyViewTypeTabs.find(tab => tab.isActive && tab.index === 0));
  }

  public get isMapTabActive(): boolean {
    return !!(this.companyViewTypeTabs.find(tab => tab.isActive && tab.index === 1));
  }

  @Watch('planJson', { immediate: true })
  public onPlanJsonChange(): void {
    if (
      this.$route.params.external_id
      || (
        this.planEventId === this.eventId
        && !this.planIsLoading
        && !this.planJson
      )
    ) {
      this.setCompaniesListType('all');
    } else {
      this.companyViewTypeTabs[1].isActive = true;
      this.companyViewTypeTabs[0].isActive = false;
    }
  }

  @Watch('planIsLoading')
  public onPlanIsLoadingChange(newVal: boolean, oldVal: boolean): void {
    if (!newVal && oldVal && !this.isPlanJson) {
      this.callSetViewTypeMutation('list');
    }
  }

  public created(): void {
    if (this.$route.params.external_id) {
      // TODO: leave only one store for this
      this.setCompaniesListType('all');
      this.callSetViewTypeMutation('list');
      this.callSetListTypeMutation('all');
    }

    this.$store.dispatch('eventStore/callEventTags', this.$route.params.eventId);
    this.$store.dispatch('promoStore/getPavilion', { eventId: this.$route.params.eventId });
  }

  public mounted(): void {
    setTimeout(() => {
      this.promoPageListAll({
        event_id: this.$route.params.eventId,
      });

      this.getSeatPlanJson({
        eventId: this.eventId,
      });
    }, 200);
  }

  public callSetViewTypeMutation(viewTypeValue: string): void {
    this.$store.commit('promoPageStore/SET_VIEW_TYPE', viewTypeValue);
  }

  public callSetListTypeMutation(listTypeValue: string): void {
    this.$store.commit('promoPageStore/SET_LIST_TYPE', listTypeValue);
  }

  public onSearchClick(value: string): void {
    const searchString = (value || '').trim();
    let countryId: number = null;

    (this.getCountryList || []).find(country => {
      if (country.name.toLowerCase() === searchString.toLowerCase()) {
        countryId = country.id;
      }
    });

    this.$store.commit('promoPageStore/SET_SEARCH', { value: searchString, countryId: countryId });
    this.onViewList();
    this.companyListTypeTabs.forEach(tab => {
      tab.isActive = tab.index === 0;
    });

    if (searchString !== this.querySearch) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: Object.assign({}, this.$route.query, {
          search: searchString || undefined,
        }),
      });
    }
  }

  public onTagsFilterClick(): void {
    this.showCompanyFilters = !this.showCompanyFilters;
  }

  public onCompanyListTypeActiveTabUpdate(activeTab: TTab, activeTabIndex: number): void {
    this.companyListTypeTabs.forEach((tab, idx) => {
      tab.isActive = idx === activeTabIndex;
    });

    this.dispatchActiveListTypeTabClickHandlers();
  }

  public dispatchActiveListTypeTabClickHandlers(): void {
    if (this.isAllCompaniesTabActive) {
      this.onAllCompaniesClick();
      return;
    }
    if (this.isMyCompaniesTabActive) {
      this.onMyCompaniesClick();
    }
  }

  public onCompanyViewTypeActiveTabUpdate(activeTab: TTab, activeTabIndex: number): void {
    this.companyViewTypeTabs.forEach((tab, idx) => {
      tab.isActive = idx === activeTabIndex;
    });

    this.dispatchActiveViewTypeTabClickHandlers();
  }

  public dispatchActiveViewTypeTabClickHandlers(): void {
    if (this.isListTabActive) {
      this.onViewList();
      return;
    }
    if (this.isMapTabActive) {
      this.onViewMap();
    }
  }

  public onAllCompaniesClick(): void {
    this.onRoutePromoCompanies();
    this.$store.commit('promoPageStore/RESET_LIST_ALL');
    this.$store.commit('promoPageStore/SET_LIST_TYPE', 'all');
    this.setListAll();
  }

  public onMyCompaniesClick(): void {
    this.onRoutePromoCompanies();
    this.$store.commit('promoPageStore/RESET_LIST_MY');
    this.$store.commit('promoPageStore/SET_VIEW_TYPE', 'list');
    this.$store.commit('promoPageStore/SET_LIST_TYPE', 'my');
    this.setListMy();
  }

  public onRoutePromoCompanies(): void {
    this.$router.push({
      name: 'promo-page-events-companies',
    }).catch(() => {
      /* ignore */
    });
  }

  public onPavilionChange(pavilionId: number): void {
    this.$store.commit('promoPageStore/SET_PAVILION_ID', pavilionId);
    this.onViewList();
  }

  public onViewMap(): void {
    this.callSetViewTypeMutation('map');
    this.callSetListTypeMutation('all');
  }

  public onViewList(): void {
    this.callSetViewTypeMutation('list');
    this.onAllCompaniesClick();
  }

  public filterTags(tags: TEventTag[]): void {
    const tagIds: number[] = [];
    tags.forEach(tag => {
      if (tag.isSelected) {
        tagIds.push(tag.id);
      }
    });
    this.selectedTags = tagIds;
    this.$store.commit('promoPageStore/SET_FILTER_IDS', tagIds);
    this.onViewList();
  }

  public resetSelectedTags(tags: TEventTag[]): void {
    const categories: Vue = this.$refs.chooseCompanyCategories as Vue;
    tags.forEach((tag, index) => {
      ((categories as unknown) as TCategoriesChooserMethods).handleTagSelected({
        ...tag,
        isSelected: false,
      }, index);
    });

    this.selectedTags = [];

    this.showCompanyFilters = false;
    this.$store.commit('promoPageStore/SET_FILTER_IDS', []);
    this.onViewList();
  }

};
